import * as i0 from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, HostBinding, Directive, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { NgControl, FormsModule } from '@angular/forms';
import { maskitoParseNumber } from '@maskito/kit';
import { tuiCreateToken, tuiProvideOptions, AbstractTuiNullableControl, tuiIsNativeFocused, tuiIsPresent, tuiClamp, TUI_IS_MOBILE, tuiAsFocusableItemAccessor, tuiAsControl } from '@taiga-ui/cdk';
import * as i2 from '@taiga-ui/core';
import { TUI_TEXTFIELD_WATCHED_CONTROLLER, TUI_NUMBER_FORMAT, TEXTFIELD_CONTROLLER_PROVIDER, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiButtonModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiTextfieldComponent } from '@taiga-ui/core';
import * as i1 from '@taiga-ui/kit/components/input-number';
import { TuiInputNumberComponent, TuiInputNumberModule } from '@taiga-ui/kit/components/input-number';
import { TUI_PLUS_MINUS_TEXTS } from '@taiga-ui/kit/tokens';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from 'rxjs';
import { TuiValueAccessorModule } from '@taiga-ui/kit/directives';

/** Default values for the input count options. */
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
function TuiInputCountComponent_ng_container_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "section", 2)(2, "button", 3);
    i0.ɵɵlistener("click", function TuiInputCountComponent_ng_container_3_ng_container_1_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.increaseValue());
    })("mousedown", function TuiInputCountComponent_ng_container_3_ng_container_1_Template_button_mousedown_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onButtonMouseDown($event, ctx_r1.plusButtonDisabled));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 4);
    i0.ɵɵlistener("click", function TuiInputCountComponent_ng_container_3_ng_container_1_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.decreaseValue());
    })("mousedown", function TuiInputCountComponent_ng_container_3_ng_container_1_Template_button_mousedown_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onButtonMouseDown($event, ctx_r1.minusButtonDisabled));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const texts_r3 = ctx.ngIf;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("appearance", ctx_r1.options.appearance)("disabled", ctx_r1.plusButtonDisabled)("focusable", false)("icon", ctx_r1.iconUp)("title", texts_r3[0]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("appearance", ctx_r1.options.appearance)("disabled", ctx_r1.minusButtonDisabled)("focusable", false)("icon", ctx_r1.iconDown)("title", texts_r3[1]);
  }
}
function TuiInputCountComponent_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiInputCountComponent_ng_container_3_ng_container_1_Template, 4, 10, "ng-container", 1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 1, ctx_r1.minusTexts$));
  }
}
const TUI_INPUT_COUNT_DEFAULT_OPTIONS = {
  icons: {
    up: 'tuiIconPlus',
    down: 'tuiIconMinus'
  },
  appearance: 'textfield',
  hideButtons: false,
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  step: 1,
  postfix: ''
};
/**
 * Default parameters for input count component
 */
const TUI_INPUT_COUNT_OPTIONS = tuiCreateToken(TUI_INPUT_COUNT_DEFAULT_OPTIONS);
function tuiInputCountOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_COUNT_OPTIONS, options, TUI_INPUT_COUNT_DEFAULT_OPTIONS);
}

/**
 * @deprecated use {@link TuiInputNumberComponent} with [step] instead
 */
class TuiInputCountComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, textfieldController, minusTexts$, isMobile, options, numberFormat) {
    super(control, cdr);
    this.textfieldController = textfieldController;
    this.minusTexts$ = minusTexts$;
    this.isMobile = isMobile;
    this.options = options;
    this.numberFormat = numberFormat;
    this.step = this.options.step;
    this.min = this.options.min;
    this.max = this.options.max;
    this.hideButtons = this.options.hideButtons;
    /** @deprecated use `tuiTextfieldPrefix` from {@link TuiTextfieldControllerModule} instead */
    this.prefix = '';
    /** @deprecated use `tuiTextfieldPostfix` from {@link TuiTextfieldControllerModule} instead */
    this.postfix = this.options.postfix;
  }
  get computedMin() {
    var _a;
    return (_a = this.min) !== null && _a !== void 0 ? _a : this.options.min;
  }
  get computedMax() {
    var _a;
    return (_a = this.max) !== null && _a !== void 0 ? _a : this.options.max;
  }
  get buttonsHidden() {
    return this.hideButtons || this.textfieldController.appearance === 'table';
  }
  get iconUp() {
    return this.options.icons.up;
  }
  get iconDown() {
    return this.options.icons.down;
  }
  get nativeFocusableElement() {
    return !this.inputNumber || this.computedDisabled ? null : this.inputNumber.nativeFocusableElement;
  }
  get size() {
    return this.textfieldController.size;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement);
  }
  get minusButtonDisabled() {
    return !this.interactive || tuiIsPresent(this.value) && this.value <= this.computedMin;
  }
  get plusButtonDisabled() {
    return !this.interactive || tuiIsPresent(this.value) && this.value >= this.computedMax;
  }
  onButtonMouseDown(event, disabled = false) {
    if (disabled || !this.nativeFocusableElement || this.isMobile) {
      return;
    }
    event.preventDefault();
    this.nativeFocusableElement.focus();
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  /**
   * @deprecated
   * TODO: drop in v4.0 as unused method
   */
  onInputNumberChange(value) {
    this.value = value;
  }
  /** @deprecated */
  onValueChange(value) {
    this.value = maskitoParseNumber(value, this.numberFormat.decimalSeparator);
  }
  decreaseValue() {
    if (this.readOnly) {
      return;
    }
    const newValue = (this.value || 0) - this.step;
    this.safeUpdateValue(newValue);
  }
  increaseValue() {
    if (this.readOnly) {
      return;
    }
    const newValue = (this.value || 0) + this.step;
    this.safeUpdateValue(newValue);
  }
  onKeydown(event) {
    switch (event.key) {
      case 'ArrowUp':
      case 'Up':
        this.increaseValue();
        event.preventDefault();
        break;
      case 'ArrowDown':
      case 'Down':
        this.decreaseValue();
        event.preventDefault();
        break;
      default:
        break;
    }
  }
  safeUpdateValue(newValue) {
    this.value = tuiClamp(newValue, this.computedMin, this.computedMax);
  }
}
TuiInputCountComponent.ɵfac = function TuiInputCountComponent_Factory(t) {
  return new (t || TuiInputCountComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_WATCHED_CONTROLLER), i0.ɵɵdirectiveInject(TUI_PLUS_MINUS_TEXTS), i0.ɵɵdirectiveInject(TUI_IS_MOBILE), i0.ɵɵdirectiveInject(TUI_INPUT_COUNT_OPTIONS), i0.ɵɵdirectiveInject(TUI_NUMBER_FORMAT));
};
TuiInputCountComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputCountComponent,
  selectors: [["tui-input-count"]],
  viewQuery: function TuiInputCountComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiInputNumberComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputNumber = _t.first);
    }
  },
  hostVars: 3,
  hostBindings: function TuiInputCountComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
      i0.ɵɵclassProp("_hide-buttons", ctx.buttonsHidden);
    }
  },
  inputs: {
    step: "step",
    min: "min",
    max: "max",
    hideButtons: "hideButtons",
    prefix: "prefix",
    postfix: "postfix"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputCountComponent), tuiAsControl(TuiInputCountComponent), TEXTFIELD_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 4,
  vars: 14,
  consts: [["decimal", "never", 1, "t-textfield", 3, "ngModelChange", "focusedChange", "keydown", "disabled", "focusable", "max", "min", "nativeId", "postfix", "prefix", "pseudoActive", "pseudoFocus", "pseudoHover", "pseudoInvalid", "readOnly", "ngModel"], [4, "ngIf"], [1, "t-buttons"], ["automation-id", "tui-input-count__plus-button", "size", "s", "tuiIconButton", "", "type", "button", 1, "t-button", "t-button_plus", 3, "click", "mousedown", "appearance", "disabled", "focusable", "icon", "title"], ["automation-id", "tui-input-count__minus-button", "size", "s", "tuiIconButton", "", "type", "button", 1, "t-button", "t-button_minus", 3, "click", "mousedown", "appearance", "disabled", "focusable", "icon", "title"]],
  template: function TuiInputCountComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "tui-input-number", 0);
      i0.ɵɵtwoWayListener("ngModelChange", function TuiInputCountComponent_Template_tui_input_number_ngModelChange_0_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
        return $event;
      });
      i0.ɵɵlistener("focusedChange", function TuiInputCountComponent_Template_tui_input_number_focusedChange_0_listener($event) {
        return ctx.onFocused($event);
      })("keydown", function TuiInputCountComponent_Template_tui_input_number_keydown_0_listener($event) {
        return ctx.onKeydown($event);
      });
      i0.ɵɵprojection(1);
      i0.ɵɵprojection(2, 1, ["ngProjectAs", "input", 5, ["input"]]);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(3, TuiInputCountComponent_ng_container_3_Template, 3, 3, "ng-container", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.focusable)("max", ctx.computedMax)("min", ctx.computedMin)("nativeId", ctx.nativeId)("postfix", ctx.postfix)("prefix", ctx.prefix)("pseudoActive", ctx.pseudoActive)("pseudoFocus", ctx.pseudoFocus)("pseudoHover", ctx.pseudoHover)("pseudoInvalid", ctx.pseudoInvalid)("readOnly", ctx.readOnly);
      i0.ɵɵtwoWayProperty("ngModel", ctx.value);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", !ctx.buttonsHidden);
    }
  },
  dependencies: [i1.TuiInputNumberComponent, i2.TuiButtonComponent, i1.TuiInputNumberDirective, i3.NgControlStatus, i3.NgModel, i4.NgIf, i4.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:flex;font:var(--tui-font-text-s);border-radius:var(--tui-radius-m);height:var(--tui-height-m);color:var(--tui-text-01);text-align:left}._disabled[_nghost-%COMP%]{pointer-events:none}[data-size=l][_nghost-%COMP%]{font:var(--tui-font-text-m);height:var(--tui-height-l)}.t-textfield[_ngcontent-%COMP%]{position:relative;border-radius:inherit;text-align:inherit;flex-grow:1;min-width:0}[_nghost-%COMP%]:not(._hide-buttons)   .t-textfield[_ngcontent-%COMP%]{border-top-right-radius:0;border-bottom-right-radius:0}.t-buttons[_ngcontent-%COMP%]{display:flex;flex-direction:column;margin-left:.125rem;height:100%;border-radius:inherit}.t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]{display:flex;width:calc(var(--tui-height-m) * .75);height:calc(50% - .0625rem);border-radius:0}.t-buttons[_ngcontent-%COMP%]   .t-button_plus[_ngcontent-%COMP%]{margin-bottom:.125rem;border-top-right-radius:inherit}.t-buttons[_ngcontent-%COMP%]   .t-button_minus[_ngcontent-%COMP%]{border-bottom-right-radius:inherit}[data-size=l][_nghost-%COMP%]   .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]{width:calc(var(--tui-height-l) * .75)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputCountComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-count',
      templateUrl: './input-count.template.html',
      styleUrls: ['./input-count.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputCountComponent), tuiAsControl(TuiInputCountComponent), TEXTFIELD_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i3.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i2.TuiTextfieldController,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_WATCHED_CONTROLLER]
      }]
    }, {
      type: i5.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_PLUS_MINUS_TEXTS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_INPUT_COUNT_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_NUMBER_FORMAT]
      }]
    }];
  }, {
    inputNumber: [{
      type: ViewChild,
      args: [TuiInputNumberComponent]
    }],
    step: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    hideButtons: [{
      type: Input
    }],
    prefix: [{
      type: Input
    }],
    postfix: [{
      type: Input
    }],
    buttonsHidden: [{
      type: HostBinding,
      args: ['class._hide-buttons']
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }]
  });
})();
class TuiInputCountDirective extends AbstractTuiTextfieldHost {
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  ngAfterViewInit() {
    if (this.host.nativeFocusableElement) {
      const {
        nativeFocusableElement
      } = this.host;
      nativeFocusableElement.autocomplete = 'off';
      nativeFocusableElement.inputMode = 'numeric';
      nativeFocusableElement.maxLength = nativeFocusableElement.maxLength > -1 ? nativeFocusableElement.maxLength : 18;
    }
  }
}
TuiInputCountDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiInputCountDirective_BaseFactory;
  return function TuiInputCountDirective_Factory(t) {
    return (ɵTuiInputCountDirective_BaseFactory || (ɵTuiInputCountDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputCountDirective)))(t || TuiInputCountDirective);
  };
})();
TuiInputCountDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiInputCountDirective,
  selectors: [["tui-input-count"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputCountDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputCountDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input-count',
      providers: [tuiAsTextfieldHost(TuiInputCountDirective)]
    }]
  }], null, null);
})();

/**
 * @deprecated use {@link TuiInputNumberComponent} with [step] instead
 */
class TuiInputCountModule {}
TuiInputCountModule.ɵfac = function TuiInputCountModule_Factory(t) {
  return new (t || TuiInputCountModule)();
};
TuiInputCountModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputCountModule
});
TuiInputCountModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule, TuiButtonModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiValueAccessorModule, TuiInputNumberModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputCountModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, TuiButtonModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiValueAccessorModule, TuiInputNumberModule],
      declarations: [TuiInputCountComponent, TuiInputCountDirective],
      exports: [TuiInputCountComponent, TuiInputCountDirective, TuiTextfieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_INPUT_COUNT_DEFAULT_OPTIONS, TUI_INPUT_COUNT_OPTIONS, TuiInputCountComponent, TuiInputCountDirective, TuiInputCountModule, tuiInputCountOptionsProvider };
