import { tuiIsNumber, tuiRoundWith, CHAR_HYPHEN, CHAR_PLUS } from '@taiga-ui/cdk';
import { TUI_DEFAULT_NUMBER_FORMAT } from '@taiga-ui/core/constants';

/**
 * Capitalizes a given string, replacing it with a lowercase string and making
 * the first letter of each word uppercase.
 *
 * @param value the input string
 * @return the capitalized string
 */
function tuiCapitalize(value) {
    return value.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase());
}

function tuiCapitalizeFirstLetter(value) {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

/**
 * Convert number to string with replacing exponent part on decimals
 *
 * @param value the number
 * @return string representation of a number
 */
function tuiNumberToStringWithoutExp(value) {
    const valueAsString = String(value);
    const [numberPart, expPart] = valueAsString.split('e-');
    let valueWithoutExp = valueAsString;
    if (expPart) {
        const [, fractionalPart] = numberPart.split('.');
        const decimalDigits = Number(expPart) + ((fractionalPart === null || fractionalPart === void 0 ? void 0 : fractionalPart.length) || 0);
        valueWithoutExp = value.toFixed(decimalDigits);
    }
    return valueWithoutExp;
}

/**
 * Return fractional part of number
 *
 * @param value the number
 * @param precision number of digits of decimal part, null to keep untouched
 * @return the fractional part of number
 */
function tuiGetFractionPartPadded(value, precision) {
    const [, fractionPartPadded = ''] = tuiNumberToStringWithoutExp(value).split('.');
    return tuiIsNumber(precision)
        ? fractionPartPadded.slice(0, Math.max(0, precision))
        : fractionPartPadded;
}

/**
 * Formats number adding a thousand separators and correct decimal separator
 * padding decimal part with zeroes to given length
 *
 * @param value the input number
 * @param settings See {@link TuiNumberFormatSettings}
 * @return the formatted string
 */
function tuiFormatNumber(value, settings = {}) {
    const { decimalLimit, decimalSeparator, thousandSeparator, zeroPadding, rounding } = Object.assign(Object.assign({}, TUI_DEFAULT_NUMBER_FORMAT), settings);
    const rounded = Number.isFinite(decimalLimit)
        ? tuiRoundWith({ value, precision: decimalLimit, method: rounding })
        : value;
    const integerPartString = String(Math.floor(Math.abs(rounded)));
    let fractionPartPadded = tuiGetFractionPartPadded(rounded, decimalLimit);
    if (Number.isFinite(decimalLimit)) {
        if (zeroPadding) {
            const zeroPaddingSize = Math.max(decimalLimit - fractionPartPadded.length, 0);
            const zeroPartString = '0'.repeat(zeroPaddingSize);
            fractionPartPadded = `${fractionPartPadded}${zeroPartString}`;
        }
        else {
            fractionPartPadded = fractionPartPadded.replace(/0*$/, '');
        }
    }
    const remainder = integerPartString.length % 3;
    const sign = value < 0 ? CHAR_HYPHEN : '';
    let result = sign + integerPartString.charAt(0);
    for (let i = 1; i < integerPartString.length; i++) {
        if (i % 3 === remainder && integerPartString.length > 3) {
            result += thousandSeparator;
        }
        result += integerPartString.charAt(i);
    }
    return fractionPartPadded ? result + decimalSeparator + fractionPartPadded : result;
}

/**
 * TODO: delete in v4.0
 * @deprecated Use {@link https://maskito.dev/core-concepts/transformer maskitoTransform} instead<br/>
 * @example
 * import {maskitoTransform} from '@maskito/core';
 *
 * const mask = {
 *     mask: ['+', '1', ' ', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
 * };
 * maskitoTransform('12125552368', mask); // +1 (212) 555-2368
 * ___
 * Formats a string with the phone format +7XXXXXXXXXXXX or XXXXXXXXXXXX,
 * adding parentheses and hyphens.
 *
 * @param value the input string
 * @param countryCode a country code
 * @param phoneMask a phone number mask
 * @return the formatted phone string of the form +7 XXX XXX-XX-XX
 */
function tuiFormatPhone(value, countryCode, phoneMask) {
    if (!value) {
        return '';
    }
    let result = countryCode;
    countryCode = countryCode.replace(/[()]/g, '');
    if (!value.startsWith(countryCode)) {
        value = countryCode + value.replace(CHAR_PLUS, '');
    }
    const splitPhoneMask = phoneMask.split('');
    const splitValue = value.slice(countryCode.length).split('');
    result += ' ';
    if (splitValue.length === 0) {
        return result;
    }
    for (const mask of splitPhoneMask) {
        if (splitValue.length === 0) {
            break;
        }
        if (mask === '#') {
            result += splitValue[0] || '';
            splitValue.splice(0, 1);
        }
        else {
            result += mask;
        }
    }
    return result;
}

function tuiOtherDecimalSymbol(symbol) {
    return symbol === '.' ? ',' : '.';
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiCapitalize, tuiCapitalizeFirstLetter, tuiFormatNumber, tuiFormatPhone, tuiGetFractionPartPadded, tuiNumberToStringWithoutExp, tuiOtherDecimalSymbol };

