import { __rest, __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { InjectionToken, ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, ContentChildren, HostBinding, HostListener, Directive, NgModule } from '@angular/core';
import * as i6 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { maskitoParseNumber, maskitoNumberOptionsGenerator, maskitoCaretGuard } from '@maskito/kit';
import * as i7 from '@taiga-ui/cdk';
import { tuiCreateToken, tuiProvideOptions, AbstractTuiNullableControl, EMPTY_QUERY, tuiClamp, CHAR_HYPHEN, CHAR_MINUS, TUI_IS_IOS, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { tuiGetFractionPartPadded, tuiFormatNumber, TUI_NUMBER_FORMAT, TUI_TEXTFIELD_SIZE, TUI_TEXTFIELD_WATCHED_CONTROLLER, TEXTFIELD_CONTROLLER_PROVIDER, TuiPrimitiveTextfieldComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiButtonModule, TuiTextfieldComponent } from '@taiga-ui/core';
import * as i5 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusOutletDirective, PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i2 from '@taiga-ui/kit/directives';
import { TuiValueAccessorModule } from '@taiga-ui/kit/directives';
import * as i3 from '@maskito/angular';
import { MaskitoModule } from '@maskito/angular';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';

/** Default values for the input number options. */
const _c0 = ["*", [["input"]], [["tuiContent"]]];
const _c1 = ["*", "input", "tuiContent"];
function TuiInputNumberComponent_div_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r2, " ");
  }
}
function TuiInputNumberComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, TuiInputNumberComponent_div_4_ng_container_1_Template, 2, 1, "ng-container", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const valueContent_r3 = i0.ɵɵreference(9);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", valueContent_r3);
  }
}
function TuiInputNumberComponent_section_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "section", 8)(1, "button", 9);
    i0.ɵɵlistener("click.prevent", function TuiInputNumberComponent_section_5_Template_button_click_prevent_1_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onArrow(ctx_r4.step));
    })("mousedown.prevent", function TuiInputNumberComponent_section_5_Template_button_mousedown_prevent_1_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.nativeFocusableElement == null ? null : ctx_r4.nativeFocusableElement.focus());
    });
    i0.ɵɵtext(2, " + ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 9);
    i0.ɵɵlistener("click.prevent", function TuiInputNumberComponent_section_5_Template_button_click_prevent_3_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onArrow(-ctx_r4.step));
    })("mousedown.prevent", function TuiInputNumberComponent_section_5_Template_button_mousedown_prevent_3_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.nativeFocusableElement == null ? null : ctx_r4.nativeFocusableElement.focus());
    });
    i0.ɵɵtext(4, " - ");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    const textfield_r6 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("appearance", textfield_r6.appearance)("disabled", !ctx_r4.canIncrement)("focusable", false)("icon", ctx_r4.options.icons.up);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("appearance", textfield_r6.appearance)("disabled", !ctx_r4.canDecrement)("focusable", false)("icon", ctx_r4.options.icons.down);
  }
}
function TuiInputNumberComponent_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiInputNumberComponent_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 2);
  }
}
const TUI_INPUT_NUMBER_DEFAULT_OPTIONS = {
  icons: {
    up: 'tuiIconPlus',
    down: 'tuiIconMinus'
  },
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  step: 0,
  decimal: 'not-zero',
  precision: 2
};
/**
 * Default parameters for input count component
 */
const TUI_INPUT_NUMBER_OPTIONS = tuiCreateToken(TUI_INPUT_NUMBER_DEFAULT_OPTIONS);
function tuiInputNumberOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_NUMBER_OPTIONS, options, TUI_INPUT_NUMBER_DEFAULT_OPTIONS);
}
const DEFAULT_MAX_LENGTH = 18;
const TUI_NUMBER_VALUE_TRANSFORMER = new InjectionToken('');
class TuiInputNumberComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, transformer, options, numberFormat, isIOS, textfieldSize, controller) {
    super(control, cdr, transformer);
    this.options = options;
    this.numberFormat = numberFormat;
    this.isIOS = isIOS;
    this.textfieldSize = textfieldSize;
    this.controller = controller;
    this.unfinishedValue = '';
    this.min = this.options.min;
    this.max = this.options.max;
    this.decimal = this.options.decimal;
    this.precision = this.options.precision;
    this.step = this.options.step;
    /** @deprecated use `tuiTextfieldPrefix` from {@link TuiTextfieldControllerModule} instead */
    this.prefix = '';
    /** @deprecated use `tuiTextfieldPostfix` from {@link TuiTextfieldControllerModule} instead */
    this.postfix = '';
    this.polymorpheusValueContent = EMPTY_QUERY;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get computedMin() {
    return this.computeMin(this.min, this.max);
  }
  get computedMax() {
    return this.computeMax(this.min, this.max);
  }
  get nativeFocusableElement() {
    return !this.textfield || this.computedDisabled ? null : this.textfield.nativeFocusableElement;
  }
  get focused() {
    var _a;
    return !!((_a = this.textfield) === null || _a === void 0 ? void 0 : _a.focused);
  }
  get isNegativeAllowed() {
    return this.computedMin < 0;
  }
  get inputMode() {
    if (this.isIOS && this.isNegativeAllowed) {
      // iPhone does not have minus sign if inputMode is equal to 'numeric' / 'decimal'
      return 'text';
    }
    return this.decimal === 'never' ? 'numeric' : 'decimal';
  }
  get calculatedMaxLength() {
    const decimalPart = this.decimal !== 'never' && this.nativeValue.includes(this.numberFormat.decimalSeparator);
    const precision = decimalPart ? Math.min(this.precision + 1, 20) : 0;
    const takeThousand = this.numberFormat.thousandSeparator.repeat(5).length;
    return DEFAULT_MAX_LENGTH + precision + takeThousand;
  }
  get formattedValue() {
    return this.value !== null ? this.getFormattedValue(this.value || 0) : '';
  }
  get computedValue() {
    return this.focused ? this.nativeValue : this.formattedValue;
  }
  get canDecrement() {
    return this.interactive && (this.value || 0) > this.computedMin;
  }
  get canIncrement() {
    return this.interactive && (this.value || 0) < this.computedMax;
  }
  get computedPrefix() {
    return this.prefix || this.controller.prefix;
  }
  get computedPostfix() {
    const postfix = this.postfix || this.controller.postfix;
    return postfix && ` ${postfix}`;
  }
  get mask() {
    return this.calculateMask(this.precision, this.decimal, this.numberFormat.decimalSeparator, this.numberFormat.thousandSeparator, this.computedMin, this.computedMax, this.computedPrefix, this.computedPostfix);
  }
  onArrow(step) {
    if (!step) {
      return;
    }
    this.value = tuiClamp((this.value || 0) + step, this.computedMin, this.computedMax);
    this.nativeValue = this.formattedValue;
  }
  onValueChange(nativeValue) {
    const parsedValue = maskitoParseNumber(nativeValue, this.numberFormat.decimalSeparator);
    this.unfinishedValue = null;
    if (Number.isNaN(parsedValue)) {
      this.value = null;
      return;
    }
    if (this.isNativeValueNotFinished) {
      this.unfinishedValue = nativeValue;
      this.cdr.markForCheck();
      return;
    }
    if (parsedValue < this.computedMin || parsedValue > this.computedMax) {
      return;
    }
    this.value = parsedValue;
  }
  onFocused(focused) {
    this.updateFocused(focused);
    const nativeNumberValue = this.unfinishedValue ? maskitoParseNumber(this.unfinishedValue, this.numberFormat.decimalSeparator) : this.nativeNumberValue;
    this.unfinishedValue = null;
    if (Number.isNaN(nativeNumberValue)) {
      this.nativeValue = focused ? this.computedPrefix + this.computedPostfix : '';
      this.value = null;
      return;
    }
    if (!focused) {
      this.value = nativeNumberValue;
      this.nativeValue = this.formattedValue;
    }
  }
  getFormattedValue(value) {
    const absValue = Math.abs(value);
    const hasFraction = absValue % 1 > 0;
    let decimalLimit = this.decimal === 'always' || hasFraction && this.decimal !== 'never' ? this.precision : 0;
    const fraction = hasFraction ? tuiGetFractionPartPadded(value, this.precision) : '';
    if (this.focused && this.decimal !== 'always') {
      decimalLimit = fraction.length;
    }
    return this.computedPrefix + tuiFormatNumber(value, Object.assign(Object.assign({}, this.numberFormat), {
      decimalLimit
    })).replace(CHAR_HYPHEN, CHAR_MINUS) + this.computedPostfix;
  }
  get isNativeValueNotFinished() {
    const nativeNumberValue = this.nativeNumberValue;
    return nativeNumberValue < 0 ? nativeNumberValue > this.computedMax : nativeNumberValue < this.computedMin;
  }
  get nativeValue() {
    var _a;
    return ((_a = this.nativeFocusableElement) === null || _a === void 0 ? void 0 : _a.value) || '';
  }
  set nativeValue(value) {
    if (!this.textfield || !this.nativeFocusableElement) {
      return;
    }
    this.textfield.value = value;
    this.nativeFocusableElement.value = value;
  }
  writeValue(value) {
    super.writeValue(value);
    this.nativeValue = this.formattedValue;
  }
  get nativeNumberValue() {
    return maskitoParseNumber(this.nativeValue, this.numberFormat.decimalSeparator);
  }
  computeMin(min, max) {
    var _a, _b, _c, _d, _e, _f;
    return Math.min((_c = (_b = (_a = this.valueTransformer) === null || _a === void 0 ? void 0 : _a.fromControlValue(min)) !== null && _b !== void 0 ? _b : min) !== null && _c !== void 0 ? _c : this.options.min, (_f = (_e = (_d = this.valueTransformer) === null || _d === void 0 ? void 0 : _d.fromControlValue(max)) !== null && _e !== void 0 ? _e : max) !== null && _f !== void 0 ? _f : this.options.max);
  }
  computeMax(min, max) {
    var _a, _b, _c, _d, _e, _f;
    return Math.max((_c = (_b = (_a = this.valueTransformer) === null || _a === void 0 ? void 0 : _a.fromControlValue(min)) !== null && _b !== void 0 ? _b : min) !== null && _c !== void 0 ? _c : this.options.min, (_f = (_e = (_d = this.valueTransformer) === null || _d === void 0 ? void 0 : _d.fromControlValue(max)) !== null && _e !== void 0 ? _e : max) !== null && _f !== void 0 ? _f : this.options.max);
  }
  calculateMask(precision, decimalMode, decimalSeparator, thousandSeparator, min, max, prefix, postfix) {
    const _a = maskitoNumberOptionsGenerator({
        decimalSeparator,
        thousandSeparator,
        min,
        max,
        prefix,
        postfix,
        precision: decimalMode === 'never' ? 0 : precision,
        decimalZeroPadding: decimalMode === 'always'
      }),
      {
        plugins
      } = _a,
      options = __rest(_a, ["plugins"]);
    return Object.assign(Object.assign({}, options), {
      plugins: [...plugins, maskitoCaretGuard(value => [prefix.length, value.length - postfix.length])]
    });
  }
}
TuiInputNumberComponent.ɵfac = function TuiInputNumberComponent_Factory(t) {
  return new (t || TuiInputNumberComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_NUMBER_VALUE_TRANSFORMER, 8), i0.ɵɵdirectiveInject(TUI_INPUT_NUMBER_OPTIONS), i0.ɵɵdirectiveInject(TUI_NUMBER_FORMAT), i0.ɵɵdirectiveInject(TUI_IS_IOS), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_WATCHED_CONTROLLER));
};
TuiInputNumberComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputNumberComponent,
  selectors: [["tui-input-number"]],
  contentQueries: function TuiInputNumberComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PolymorpheusOutletDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.polymorpheusValueContent = _t);
    }
  },
  viewQuery: function TuiInputNumberComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiInputNumberComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown.arrowDown", function TuiInputNumberComponent_keydown_arrowDown_HostBindingHandler() {
        return ctx.onArrow(-ctx.step);
      })("keydown.arrowUp", function TuiInputNumberComponent_keydown_arrowUp_HostBindingHandler() {
        return ctx.onArrow(ctx.step);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    min: "min",
    max: "max",
    decimal: "decimal",
    precision: "precision",
    step: "step",
    prefix: "prefix",
    postfix: "postfix"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputNumberComponent), tuiAsControl(TuiInputNumberComponent), TEXTFIELD_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 10,
  vars: 14,
  consts: [["textfield", ""], ["valueContent", ""], ["tuiTextfieldPostfix", "", "tuiTextfieldPrefix", "", "tuiValueAccessor", "", 1, "t-textfield", 3, "focusedChange", "valueChange", "disabled", "focusable", "invalid", "maskito", "pseudoActive", "pseudoFocus", "pseudoHover", "readOnly", "value"], ["ngProjectAs", "tuiContent", "class", "t-value-content", 5, ["tuiContent"], 4, "ngIf"], ["class", "t-buttons", 4, "ngIf"], [4, "ngIf"], ["ngProjectAs", "tuiContent", 5, ["tuiContent"], 1, "t-value-content"], [4, "polymorpheusOutlet"], [1, "t-buttons"], ["size", "s", "tuiIconButton", "", "type", "button", 1, "t-button", 3, "click.prevent", "mousedown.prevent", "appearance", "disabled", "focusable", "icon"]],
  template: function TuiInputNumberComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "tui-primitive-textfield", 2, 0);
      i0.ɵɵlistener("focusedChange", function TuiInputNumberComponent_Template_tui_primitive_textfield_focusedChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocused($event));
      })("valueChange", function TuiInputNumberComponent_Template_tui_primitive_textfield_valueChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onValueChange($event));
      });
      i0.ɵɵprojection(2);
      i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
      i0.ɵɵtemplate(4, TuiInputNumberComponent_div_4_Template, 2, 1, "div", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(5, TuiInputNumberComponent_section_5_Template, 5, 8, "section", 4)(6, TuiInputNumberComponent_ng_container_6_Template, 1, 0, "ng-container", 5);
      i0.ɵɵpipe(7, "async");
      i0.ɵɵtemplate(8, TuiInputNumberComponent_ng_template_8_Template, 1, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.focusable)("invalid", ctx.computedInvalid)("maskito", ctx.mask)("pseudoActive", ctx.pseudoActive)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("value", ctx.computedValue);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngIf", ctx.polymorpheusValueContent.length);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.step);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(7, 12, ctx.polymorpheusValueContent.changes));
    }
  },
  dependencies: [i1.TuiPrimitiveTextfieldComponent, i1.TuiButtonComponent, i1.TuiPrimitiveTextfieldDirective, i1.TuiTextfieldPostfixDirective, i1.TuiTextfieldPrefixDirective, i2.TuiValueAccessorDirective, i3.MaskitoDirective, i4.NgIf, i5.PolymorpheusOutletDirective, i4.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:flex;border-radius:var(--tui-radius-m);text-align:left}.t-textfield[_ngcontent-%COMP%]{flex:1;max-width:100%;border-radius:inherit;text-align:inherit}.t-textfield[_ngcontent-%COMP%]:not(:last-child){border-top-right-radius:0;border-bottom-right-radius:0}.t-value-content[_ngcontent-%COMP%]{width:100%}.t-buttons[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:stretch;margin-left:.125rem;border-radius:inherit}[data-size=s][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]{flex-direction:row-reverse}[data-size=s][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]:first-child{margin:0 0 0 .125rem;border-top-right-radius:inherit;border-bottom-right-radius:inherit}[data-size=s][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]:last-child{border-radius:0}[data-size=m][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]{width:calc(var(--tui-height-m) * .75)}[data-size=l][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]{width:calc(var(--tui-height-l) * .75)}.t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]{display:flex;flex:1;height:auto;border-radius:0}.t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]:first-child{margin-bottom:.125rem;border-top-right-radius:inherit}.t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]:last-child{border-bottom-right-radius:inherit}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiInputNumberComponent.prototype, "computeMin", null);
__decorate([tuiPure], TuiInputNumberComponent.prototype, "computeMax", null);
__decorate([tuiPure], TuiInputNumberComponent.prototype, "calculateMask", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputNumberComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-number',
      templateUrl: './input-number.template.html',
      styleUrls: ['./input-number.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputNumberComponent), tuiAsControl(TuiInputNumberComponent), TEXTFIELD_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i6.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i7.AbstractTuiValueTransformer,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_NUMBER_VALUE_TRANSFORMER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_INPUT_NUMBER_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_NUMBER_FORMAT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_IOS]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }, {
      type: i1.TuiTextfieldController,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_WATCHED_CONTROLLER]
      }]
    }];
  }, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    decimal: [{
      type: Input
    }],
    precision: [{
      type: Input
    }],
    step: [{
      type: Input
    }],
    prefix: [{
      type: Input
    }],
    postfix: [{
      type: Input
    }],
    polymorpheusValueContent: [{
      type: ContentChildren,
      args: [PolymorpheusOutletDirective, {
        descendants: true
      }]
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }],
    onArrow: [{
      type: HostListener,
      args: ['keydown.arrowDown', ['-step']]
    }, {
      type: HostListener,
      args: ['keydown.arrowUp', ['step']]
    }],
    computeMin: [],
    computeMax: [],
    calculateMask: []
  });
})();
class TuiInputNumberDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.computedValue;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  ngDoCheck() {
    if (!this.host.nativeFocusableElement) {
      return;
    }
    this.host.nativeFocusableElement.inputMode = this.host.inputMode;
    this.host.nativeFocusableElement.maxLength = this.host.nativeFocusableElement.maxLength > 0 ? this.host.nativeFocusableElement.maxLength : this.host.calculatedMaxLength;
  }
}
TuiInputNumberDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiInputNumberDirective_BaseFactory;
  return function TuiInputNumberDirective_Factory(t) {
    return (ɵTuiInputNumberDirective_BaseFactory || (ɵTuiInputNumberDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputNumberDirective)))(t || TuiInputNumberDirective);
  };
})();
TuiInputNumberDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiInputNumberDirective,
  selectors: [["tui-input-number"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputNumberDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputNumberDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input-number',
      providers: [tuiAsTextfieldHost(TuiInputNumberDirective)]
    }]
  }], null, null);
})();
class TuiInputNumberModule {}
TuiInputNumberModule.ɵfac = function TuiInputNumberModule_Factory(t) {
  return new (t || TuiInputNumberModule)();
};
TuiInputNumberModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputNumberModule
});
TuiInputNumberModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, MaskitoModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiValueAccessorModule, PolymorpheusModule, TuiButtonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputNumberModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MaskitoModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiValueAccessorModule, PolymorpheusModule, TuiButtonModule],
      declarations: [TuiInputNumberComponent, TuiInputNumberDirective],
      exports: [TuiInputNumberComponent, TuiInputNumberDirective, TuiTextfieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_INPUT_NUMBER_DEFAULT_OPTIONS, TUI_INPUT_NUMBER_OPTIONS, TUI_NUMBER_VALUE_TRANSFORMER, TuiInputNumberComponent, TuiInputNumberDirective, TuiInputNumberModule, tuiInputNumberOptionsProvider };
